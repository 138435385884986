@import "minima";

@import url('https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,400i,700,700i&display=swap');
@import url('https://cdn.jsdelivr.net/npm/katex@0.11.1/dist/katex.min.css');

blockquote {
  letter-spacing: inherit;
}

code, pre {
  font-family: 'Ubuntu Mono', monospace;
}
